<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:customizeActionAvailability="customizeActionAvailability"
			:modelColumnDefs="modelColumnDefs"
			:parentModel="parentModel"
			:parentModelName="parentModelName"
			:externalFilter="externalFilter"
			:readOnly="readOnly"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
		></pui-datatable>
	</div>
</template>

<script>

import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import { STATUS_DRAFT_ID } from '../../constants/imofalforms/ImoFalFormsConstants';

export default {
	name: 'imofalform3items-grid',
	mixins: [PuiGridDetailMixin],
	components: {},
	data() {
		return {
			modelName: 'imofalform3items',
			parentModelName: 'imofalform3',
			modelColumnDefs: {}
		};
	},
	methods: {
		customizeActionAvailability(actionId, parentModel) {
			if (actionId === 'puiImportAction' || actionId === 'puiExportAction') {
				return parentModel.imofalforms.statusid === STATUS_DRAFT_ID;
			}
		}
	}
};
</script>
